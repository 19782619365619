@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@font-face {
  font-family: 'Lato';
  src:
    local('Lato'),
    local('Lato'),
    url('../fonts/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'LatoBold';
  src:
    local('LatoBold'),
    local('LatoBold'),
    url('../fonts/Lato-Bold.woff') format('woff'),
    url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$time: 10s;
$timeDelay: $time / 30;
$cube-height: 35px;

a,
input,
textarea,
small,
select,
div,
label {
  font-family: 'Lato';
}

p {
  font-size: 15px;
  line-height: 1.43;
  text-align: left;
  color: #282828;
  font-family: 'Lato';
}

h1,
h2 {
  font-family: 'LatoBold';
}

h1,
h2,
h3,
h4 {
  font-size: 40px;
  line-height: 1.17;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
}

h2,
h3 {
  margin-bottom: 25px;
}

h4 {
  margin-bottom: 15px;
}

a {
  text-decoration: none;
}

input,
select,
button,
img,
a {
  outline: none;
}

@mixin placeholder {
  ::placeholder {
    @content;
  }
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin buttonYellow {
  width: 100%;
  color: #282828;
  text-transform: uppercase;
  line-height: 35px;
  font-size: 12px;
  font-family: 'LatoBold';
  letter-spacing: 1.1px;
  margin: 25px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffd738;
  text-align: center;
  border: none;
  cursor: pointer;
}

@mixin imageShadow {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  animation: shadow $time linear infinite;
}

.section-in {
  position: relative;
  z-index: 2;
  max-width: 1024px;
  padding: 120px 20px 65px;
  // min-height: calc(100vh - 190px);
  min-height: 900px;
  margin: 0 auto;
}

.back {
  display: inline-block;
  padding: 10px 10px 10px 0;
  margin-bottom: 25px;
  cursor: pointer;
  & p {
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'LatoBold';
    margin-left: 5px;
    line-height: 1;
  }
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    fill: none;
    stroke: #282828;
    stroke-width: 3px;
    height: 11px;
    width: auto;
    display: block;
  }
  &.white {
    svg {
      stroke: #ffffff;
    }
    p {
      color: #ffffff;
    }
  }
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  height: 60px;
  z-index: 20;
  padding: 0;
  &-social-icon {
    margin-left: 20px;
  }
  &-in {
    background: #ffd738;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 0 20px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    height: 50px;
    z-index: -1;
    pointer-events: none;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  }
  &.open {
    height: 100%;
    .header-menu {
      height: calc(100vh - 55px);
      overflow-y: scroll;
    }
  }
  &-logo {
    display: block;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
    height: 56px;
    &-menu {
      display: flex;
      align-items: center;
    }
    & .logo svg {
      width: 110px;
      height: 29px;
      z-index: 2;
      display: block;
      position: relative;
      margin-right: 20px;
    }
    .menu-button {
      position: relative;
      width: 25px;
      height: 10px;
      z-index: 2;
      margin-left: 10px;
      display: none;
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        background: #282828;
        height: 3px;
      }
      &:before {
        width: 80%;
        bottom: 0;
        animation: inBtm 0.5s backwards;
        animation-direction: reverse;
        transition: 0.5s;
      }
      &:after {
        width: 100%;
        top: 0;
        animation: inT 0.5s backwards;
        animation-direction: reverse;
      }
      &-icon {
        padding: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      &.open {
        &:before {
          width: 100%;
          animation: outBtm 0.5s forwards;
        }
        &:after {
          animation: outT 0.5s forwards;
        }
      }
    }
  }
  .header-menu {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.5s;
    padding: 0 10px;
    height: 0;
    &-in {
      max-width: 1024px;
      margin: 0 auto;
      opacity: 0;
      pointer-events: none;
      transition: 0.5s;
      height: 100%;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
    }
  }
  .header-city {
    &-field {
      width: 100%;
      background: none;
      border: none;
      color: #fff;
      font-size: 15px;
      margin-top: 5px;
    }
    &-main {
      width: 25%;
      height: 100%;
    }
    &-container {
      display: flex;
      width: 100%;
    }
    &-block {
      width: 100%;
      position: relative;
      padding: 0 0 21px 30px;
      float: left;
      page-break-inside: avoid;
      break-inside: avoid;
    }
    &-alphabet {
      width: 75%;
      position: relative;
      column-count: 2;
    }
    &-title {
      width: 100%;
      padding: 15px 0;
      margin-bottom: 15px;
      border-bottom: 1px solid #fff;
      @include placeholder {
        color: #fff;
      }
    }
    &-item {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 10px;
      cursor: pointer;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.allcity {
        margin-bottom: 40px;
      }
      &.wrong-city {
        position: absolute;
        top: 0;
        left: 0;
        white-space: initial;
        overflow: inherit;
      }
      &.letter {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        cursor: inherit;
      }
    }
  }
  .menu-button-title {
    padding: 0 5px;
    color: #000000;
    font-size: 13px;
    font-family: 'LatoBold';
    line-height: 1;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      height: 18px;
      width: 18px;
      position: relative;
      margin: 0 5px 0 0;
      background: url('../images/location-pin.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &.open {
    .menu-button-title .arrow-small svg {
      transform: rotate(180deg);
    }
    .header-menu-in {
      opacity: 1;
      pointer-events: auto;
      height: auto;
    }
  }
  .header-top .header-menu-block {
    display: flex;
    align-items: center;
    a {
      font-size: 13px;
      font-family: 'LatoBold';
      color: #000000;
      position: relative;
      display: block;
      margin: 0 10px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      &:hover {
        color: #ffffff;
      }
    }
  }
  .header-menu .header-menu-block {
    display: none;
  }
}

.header-auth {
  margin-left: 20px;
  position: relative;
  &-icon {
    svg {
      display: block;
    }
  }
  &-buttons {
    position: absolute;
    top: 38px;
    right: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.75);
    min-width: 140px;
    padding: 18px;
    display: none;
  }
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      display: block;
    }
    .user-icon {
      fill: #ffffff;
    }
  }
  &-title {
    font-family: 'LatoBold';
    color: #ffffff;
    padding: 4px 0 0 10px;
    font-size: 13px;
    user-select: none;
    white-space: nowrap;
  }
  &-icon {
    display: flex;
    align-items: center;
  }
  &.open {
    .arrow-small svg {
      transform: rotate(180deg);
    }
    .header-auth-buttons {
      display: block;
    }
  }
}

.main {
  position: relative;
  width: 100%;
  max-width: 1920px;
  max-height: 100vh;
  margin: 0 auto;

  &:before {
    @include imageShadow;
  }

  .mainpage-group {
    height: 100%;
    &::before {
      animation: fade 1s linear forwards;
    }
    &.no-blocks {
      background-color: red;
      .text-group {
        p {
          opacity: 0;
          animation: fade 1s linear forwards;
        }
        .lines span:nth-child(1) {
          animation: lineTopStop 0.5s linear forwards;
        }
        .lines span:nth-child(2) {
          animation: lineBottomStop 0.5s linear forwards;
        }
      }

    }
    img {
      max-height: 100vh;
      object-fit: cover;
      object-position: center;
    }
    .text-group {
      top: 25%;
      bottom: auto;
      left: 45%;
      transform: translate(-50%, -50%);
    }
    .block-group {
      top: 43%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
  }
  & .arrow_scroll {
    position: absolute;
    width: 40px;
    height: 45px;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    span {
      display: block;
    }
    & svg {
      display: block;
      width: 40px;
      height: 45px;
    }
  }
  .button-yellow {
    max-width: 340px;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.arrow-small svg {
  margin-left: 5px;
  display: block;
}

.profile-container {
  padding: 30px 30px 50px;
  max-width: 560px;
  margin: 0 auto;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.24),
    0 -1px 5px 0 rgba(0, 0, 0, 0.12);
  .form-container {
    width: calc(100% - 60px);
    padding: 0;
    margin: 0 auto;
    .form-field {
      position: relative;
    }
    .form-field,
    .form-field + label {
      color: #282828;
      z-index: 0;
    }
    .form-field[disabled],
    .form-field[disabled] + label {
      opacity: 0.5;
    }
  }
  .form-submit {
    .button-icon {
      display: block;
      margin: 0 10px 4px 0;
    }
    svg {
      display: block;
      width: 14px;
      height: 14px;
    }
  }
}

.profile-title {
  font-size: 21px;
  font-family: 'LatoBold';
  margin-bottom: 40px;
}
.profile-description {
  text-align: center;
  margin-bottom: 20px;
}

.icon-edit {
  padding-right: 10px;
  padding-bottom: 5px;
  svg {
    display: block;
  }
}

.user-icon {
  border-radius: 50%;
  background: #000000;
  color: #ffd738;
  width: 18px;
  height: 18px;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  font-family: 'LatoBold';
  position: relative;
  span {
    position: absolute;
    top: 50%;
    left: 0;
    line-height: 1;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
  }
}

.button-yellow {
  @include buttonYellow;
  margin: 25px auto;
}

.case {
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto;
}

.case-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
  align-items: start;
  & > div {
    width: 50%;
    position: relative;
    height: auto;
    overflow: hidden;
  }
  &.reversed,
  &:nth-child(even) {
    .case-image {
      order: -1;
    }
  }
}

.case-image {
  img {
    display: block;
    width: 100%;
    min-height: 300px;
    object-fit: cover;
  }
}

.case-link {
  @include buttonYellow;
  margin: 25px auto;
}

.case-text {
  padding: 0 25px;
}

.case-image {
  position: relative;
  &:before {
    @include imageShadow;
  }
}

.event {
  .case-image:before {
    display: none;
  }
}

.slider {
  .slider-wrapper {
    margin: 50px 0;
    padding: 0 10px;
  }
}

.proposals {
  .description {
    margin-bottom: 50px;
  }
}

.service-status a,
.description a {
  color: #3598db;
}

.slick-dots {
  position: relative;
  bottom: 0;
  padding: 0 15px;
}

.slick-track {
  display: flex;
  margin-bottom: 20px;
}

.slick-slide {
  height: auto;
  & > div {
    height: 100%;
    .item {
      margin: 0 auto;
      height: 100%;
    }
  }
}

.slick-slider {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  .slick-list {
    width: calc(100% - 50px);
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    li button:before {
      content: '';
      background: #282828;
      border-radius: 50px;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .slick-prev,
  .slick-next {
    width: 20px;
    height: 20px;
    z-index: 10;
    &:before {
      content: '';
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.25;
    }
    &:hover:before {
      opacity: 1;
    }
  }
  .slick-prev {
    left: 0;
    &:before {
      background: url('../images/back.svg');
    }
  }
  .slick-next {
    right: 0;
    &:before {
      background: url('../images/next.svg');
    }
  }
}

.university {
  background: #000000;
  color: #ffffff;
  .title {
    position: relative;
    font-family: 'LatoBold';
    text-align: center;
    margin: 70px 0 40px;
    color: #ffffff;
    &:before {
      content: '';
      position: absolute;
      width: 54px;
      height: 40px;
      background: url('../images/logo-uni.svg');
      background-size: contain;
      top: -70px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .description {
    margin-bottom: 50px;
  }
  .image {
    position: relative;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
      min-height: 400px;
      object-fit: cover;
      object-position: center;
    }
    .block-group {
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      & div,
      & p {
        animation: none;
      }
    }
  }
  .link {
    position: relative;
    display: flex;
    width: 246px;
    margin: 40px auto 0;
    p {
      width: 100%;
      text-align: center;
      font-family: 'LatoBold';
      line-height: 38px;
      letter-spacing: 1.1px;
      font-size: 12px;
      color: #ffd600;
      text-transform: uppercase;
      z-index: 2;
    }
    &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: 0;
      width: 100%;
      height: 100%;
      background: url('../images/line.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }
  .block-items {
    margin: 50px 0 40px;
  }
  & p {
    color: #ffffff;
  }
}

.form {
  position: relative;
  min-height: 100vh;
  z-index: 0;
  
  &::after,
  &:before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;
    left: 0;
    top: 0;
    z-index: 2;
  }
  &::after {
    background-image: url('../images/want_to_lifecell.jpg');
    background-size: cover;
    background-position: center;
    z-index: 1;
    filter: blur(5px);
    transform: scale(1.1);
  }
  .form-in {
    display: flex;
    flex-wrap: wrap;
    & > div:not(.form-part) {
      width: 100%;
    }
  }
  .form-part {
    width: 50%;
    padding: 0 5%;
    .button-yellow {
      margin-top: 0;
    }
  }

  h3 {
    margin-bottom: 50px;
    font-size: 20px;
    color: #fff;
    .title {
      font-size: 20px;
    }
    a {
      color: #fff;
      text-decoration: underline;
      font-family: inherit;
    }
  }

  .form-msisdn {
    color: #ffffff;
    margin-bottom: 20px;
    font-weight: bold;
  }
}

.form-container {
  position: relative;
  background: #ffffff;
  padding: 45px 20px;
  .spinner {
    z-index: 10;
  }
  & > div {
    margin: 0 0 5px 0;
    position: relative;
    & > div:after {
      border-width: 1px;
    }
    &.spinner {
      position: absolute;
    }
  }
  small {
    color: #ff0000;
    font-size: 10px;
    display: block;
  }
  input[disabled] {
    color: #282828;
    opacity: 0.3;
    & + label {
      color: #cccccc;
    }
  }
  input + label,
  .form-select label {
    color: #282828;
    font-size: 14px;
    padding: 0;
  }

  .form-field {
    font-size: 14px;
    & + label {
      font-size: 14px;
      color: #282828;
    }
    &.document_student {
      text-transform: uppercase;
    }
  }

  .form-checkbox {
    font-size: 10px;
    color: #282828;
    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  .form-select {
    & > label {
      left: -15px;
    }
    &.Select__select-outlinedDisabled {
      opacity: 0.5;
      label {
        color: #282828;
      }
    }
    & > div > div {
      font-size: 14px;
      border-radius: 0 !important;
      min-height: auto !important;
      margin-top: 14px;
      border-width: 0 0 1px 0 !important;
      .select-outlined__value-container {
        height: 30px;
        margin-top: 7px;
        padding: 0 !important;
        & > div {
          padding: 0 20px 0 0;
          color: #282828;
          width: 100%;
        }
      }
    }
    .select-outlined__menu {
      padding: 0;
      margin: 0;
    }
    .select-outlined__menu-list {
      padding: 0;
      margin: 0;
      height: auto;
      min-height: 26px;
      max-height: 280px;
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
    .select-outlined__option {
      font-size: 14px !important;
      padding: 5px 10px !important;
    }
    .select-outlined__option--is-selected {
      background-color: #ffd738 !important;
      color: #282828 !important;
    }
    &.gender {
      .select-outlined__option:last-child {
        display: none;
      }
    }
    .select-outlined__loading-indicator {
      display: block;
      position: relative;
      top: 5px;
      & ~ .select-outlined__dropdown-indicator {
        display: none;
      }
    }
    .select-outlined__dropdown-indicator {
      border-style: hidden;
      width: 8px;
      height: 8px;
      border-bottom: 1px solid #949494;
      border-left: 1px solid #949494;
      position: absolute;
      right: 5px;
      bottom: 12px;
      transform: rotate(-45deg);
      padding: 2px;
      svg {
        display: none;
      }
    }
  }

  .form-checkbox-block {
    display: inline-block;
    padding: 10px 0 10px 25px;
    margin-top: 12px;
    position: relative;
    cursor: pointer;
    input {
      display: none;
      &:not(:checked) ~ span:after {
        display: none;
      }
    }
    span {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: -25px;
        bottom: -2px;
        width: 15px;
        height: 15px;
        border: 1px solid #282828;
        background: #fff;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 3px;
        left: -19px;
        width: 3px;
        height: 7px;
        border-right: 2px solid #282828;
        border-bottom: 2px solid #282828;
        transform: rotate(40deg);
      }
    }
  }
}

.form-profile-status {
  .success,
  .error {
    font-size: 14px;
    margin-top: 25px;
    text-align: center;
    color: #282828;
  }
}

.form-text {
  h3 {
    text-align: center;
  }
  .button-yellow {
    max-width: 340px;
  }
}

.form-submit {
  @include buttonYellow;
}

.discount-description {
  margin-top: 25px;
  font-size: 14px;
}

.recaptcha {
  opacity: 0;
  pointer-events: none;
}

.service-status {
  padding: 15px 0;
  text-align: center;
  color: #282828;
  font-size: 15px;
  z-index: 1;
}

.offer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  & > img,
  & > div {
    width: calc(50% - 20px);
    margin-bottom: 25px;
    a.offer-buy {
      color: #282828;
    }
    a {
      color: #3598db;
    }
  }
  &-order {
    p {
      text-align: center;
    }
    .offer-buy {
      margin: 10px auto;
    }
  }
  .offer-title {
    font-size: 16px;
    font-family: 'LatoBold';
    color: #282828;
    margin: 15px 0 25px;
  }
  .offer-description {
    margin: 0 0 25px;
    * {
      font-family: 'Lato' !important;
      font-size: 14px !important;
    }
  }
}

.disable-discount {
  text-decoration: underline;
  cursor: pointer;
}

.offer-buy {
  @include buttonYellow;
  min-width: 180px;
  max-width: 500px;
}

.offer-container {
  .submit {
    margin-top: 0;
  }
  .form-container {
    padding: 10px 0px 45px;
  }
}

.offer-buttons {
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    width: 230px;
    margin: 10px;
  }
}

.block-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  &-offer {
    width: 100%;
    text-align: center;
    .button-yellow {
      max-width: 340px;
      display: inline-block;
    }
  }
  .item {
    width: 220px;
    margin: 0 10px 20px;
    background: #fff;
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.24),
      0 -1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .item-top {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .item-bottom {
    padding: 20px;
    text-align: center;
    height: calc(100% - 150px);
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .item-text {
    font-size: 14px;
    color: #282828;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .item-link {
    @include buttonYellow;
  }
  svg {
    display: block;
  }
}

.success-page .spinner {
  position: relative;
  height: 60px;
  background: none;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  &-in {
    width: 70px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    &.line {
      position: fixed;
    }
    & > div {
      width: 18px;
      height: 18px;
      background-color: #ffd600;
      border-radius: 100%;
      display: inline-block;
      animation: spinner 1.4s infinite ease-in-out both;
      &:nth-child(1) {
        animation-delay: -0.32s;
      }
      &:nth-child(2) {
        animation-delay: -0.16s;
        display: block;
      }
    }
  }
}

.submit {
  margin: 0 auto 25px;
  width: 100%;
  display: block;
  max-width: 500px;
  line-height: 35px;
  font-size: 12px;
  font-family: 'LatoBold';
  letter-spacing: 1.1px;
  text-align: center;
  color: #282828;
  text-transform: uppercase;
  background: #ffd600;
  opacity: 1;
  cursor: pointer;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.footer {
  background: #4a4a4a;
  position: relative;
  & .menu {
    padding: 25px 20px;
    &-in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1024px;
      margin: 0 auto;
    }
    & .lifecell_logo {
      width: 67px;
      margin-right: 0;
      span {
        display: block;
      }
      & svg {
        width: 67px;
        height: 22px;
      }
    }
    & a {
      color: #ffffff;
      margin-right: 30px;
      font-size: 12px;
    }
  }
  & .social {
    border-top: 1px solid #ffd600;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    & > * {
      padding: 0 25px;
    }
  }
}

.law-links {
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
  a {
    color: #ffd600;
    padding: 10px;
    font-size: 10px;
    margin: 0 10px;
  }
}

.personal {
  .button-yellow {
    margin: 25px auto 0;
  }
  .detail-items {
    margin-bottom: 40px;
    & ul {
      margin: 0 20px;
    }
  }
  .detail {
    position: relative;
    padding-bottom: 2px;
    padding-right: 30px;
    border-bottom: 1px solid #ffd600;
    cursor: pointer;
    user-select: none;
    &:after {
      content: '';
      width: 8px;
      height: 8px;
      border-bottom: 1px solid #282828;
      border-left: 1px solid #282828;
      position: absolute;
      right: 10px;
      top: 2px;
      transform: rotate(-45deg);
    }
  }
  .detail-item {
    padding-top: 28px;
    & .open + .detail-block {
      & p {
        letter-spacing: 0.4px;
        text-indent: 0px;
        text-align: justify;
      }
      & ul {
        margin-left: 20px;
      }
    }
  }
  .detail.open {
    &:after {
      transform: rotate(135deg);
      top: 8px;
    }
  }
  .detail-block {
    display: none;
  }
  .open + .detail-block {
    display: block;
    margin: 10px 0px 0;
    & p,
    & li,
    & div {
      font-size: 14px;
      letter-spacing: 0.4px;
      text-align: left;
    }
    & li {
      margin-left: 10px;
    }
  }
}

.full-conditions {
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 20px;
  text-decoration: underline;
}

.notfound {
  position: relative;
  height: calc(100vh - 56px);
  background-image: url('../images/notfound-image.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  & .text {
    width: 320px;
    & h4 {
      font-size: 48px;
      font-family: 'LatoBold';
      line-height: 0.83;
      letter-spacing: 0.5px;
      text-align: center;
    }
    & p {
      font-size: 20px;
      font-family: 'LatoBold';
      line-height: 1.1;
      letter-spacing: 0.3px;
      text-align: center;
      margin-top: 40px;
    }
  }
  & .number {
    position: absolute;
    width: 70vw;
    max-width: 700px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  & .astronaut {
    position: absolute;
    width: 60vw;
    max-width: 450px;
    top: 15%;
    left: 50%;
    transform-origin: 50% 0%;
    transform: scale(1);
    transform-box: fill-box;
    animation: astronaut $time cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  }
  & .fog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 56px);
    background-image: url('../images/fog1.png');
    background-size: cover;
    background-repeat: repeat-x;
    background-position: center;
    pointer-events: none;
    opacity: 0.8;
    animation: fog 60s linear infinite;
  }
}

.byf-group,
.club-group,
.simagochi-group,
.proposals-group {
  .block-group {
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.block-group {
  display: flex;
  width: 120px;
  flex-wrap: wrap;
  position: absolute;

  & > div {
    width: 120px;
    height: 110px;
    position: relative;
    border: 2px solid #ffffff;
    padding: 10px;
    overflow: hidden;
    &:first-child {
      opacity: 1;
      animation: caseTop $time linear infinite;
      border-bottom: none;
    }
    &:last-child {
      opacity: 1;
      animation: caseBottom $time linear infinite;
    }
  }

  p {
    color: #ffffff;
    font-family: 'LatoBold';
    font-size: 16.5px;
    line-height: 1.4;
    position: relative;
    &:nth-child(1) {
      animation: blockText $time linear infinite;
    }
    &:nth-child(2) {
      animation: blockText $time $timeDelay linear infinite;
    }
    &:nth-child(3) {
      animation: blockText $time $timeDelay * 2 linear infinite;
    }
  }

  .line {
    display: block;
    padding-top: 5px;
    position: relative;
    animation: blockText $time $timeDelay * 3 linear infinite;
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .block-text {
    background: rgba(0, 0, 0, 0.5);
  }
}

.text-group {
  position: absolute;
  bottom: 60px;
  left: 60px;
  p {
    font-size: 40px;
    font-family: 'LatoBold';
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1;
    opacity: 1;
    animation: shadow $time linear infinite;
    &:nth-child(2) {
      margin-left: 25px;
    }
    &:nth-child(3) {
      margin-left: 50px;
    }
  }
  .lines span {
    display: inline-block;
    position: absolute;
    svg {
      display: block;
    }
    &:nth-child(1) {
      top: -20px;
      left: -25px;
      animation: lineTop $time linear infinite;
    }
    &:nth-child(2) {
      bottom: -15px;
      right: -25px;
      animation: lineBottom $time linear infinite;
      animation-delay: 0.3s;
    }
  }
}

.loader-container {
  position: relative;
  width: 100%;
  height: 100px;
}

.invite-block {
  text-align: center;
  margin: 20px 0;
}

.invite-block-link {
  position: relative;
  text-align: center;
  padding: 0 20px 0px 30px;
  color: #4c4c4e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 10px;
    bottom: 4px;
    background: url('../images/link.svg');
  }
}

.invite-button {
  @include buttonYellow;
  margin: 25px auto;
}

.invite-error {
  margin: 25px auto;
  text-align: center;
}

.invite-share {
  display: flex;
  margin-bottom: 20px;
}

.invite-description {
  font-size: 13px;
  text-align: center;
  color: #4c4c4e;
  display: block;
  margin: 10px 0 30px;
  &.link {
    margin: 10px 0;
    text-decoration: underline;
  }
}

.invite-share-btn {
  height: $cube-height;
  transition: all 250ms ease;
  transform-style: preserve-3d;
  text-align: center;
  cursor: pointer;
  &.copy {
    flex-grow: 3;
    margin-right: 10px;
  }
  &.social {
    flex-grow: 1;
  }
  &.social:hover,
  &.active {
    transform: rotateX(-90deg);
    .default-state {
      background: #ffd738;
    }
  }
  svg {
    display: block;
    width: 15px;
    height: 35px;
    margin: 0 auto;
  }
}

.default-state,
.active-state {
  font-family: 'LatoBold';
  letter-spacing: 1.1px;
  background: #ffd738;
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #282828;
  height: $cube-height;
  line-height: $cube-height;
  transition: background 250ms ease;
}

.default-state {
  transform: translateZ($cube-height/2);
}
.active-state {
  background: #fff;
  transform: rotateX(90deg) translateZ($cube-height * 1.5);
}

.message-error {
  position: relative;
  top: 0;
  margin-bottom: 10px;
}

.change-language {
  margin-right: 20px;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
  text-transform: uppercase;
  display: flex;
}

.change-language.in-header-menu {
  display: none;
  color: #ffffff;
  font-size: 16px;
}

.change-language-language {
  display: block;
  margin-left: 4px;
}

.change-language.in-header-menu .change-language-language {
  opacity: 0.5;
}

.language-active {
  font-weight: 600;
}

.change-language.in-header-menu .language-active {
  opacity: 1;
}

#modal {
  position: absolute;
  top: 0;
  left: 0;
}
.modal {
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
}
.modal-overlay{
  position: absolute;
  background: rgba(0, 0, 0, 0.90);
  width: 100%;
  height: 100%;
}
.modal-content {
  border-radius: 8px;
  background: #FFF; 
  padding: 27px 24px 24px;
  position: relative;
  z-index: 1;
  min-width: 300px;
  margin: 20px;
}
.modal-trigger {
  cursor: pointer;
  display: block;
}
.modal-close {
  display: block;
  width: 14px;
  height: 14px;
  background: url('../images/cross.svg') no-repeat center;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.delete-card-button {
  display: inline-block;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: #DC4038;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline; 
  color: #DC4038;
  display: flex;
  align-items: flex-end;
}
.delete-card-button::before {
  content: '';
  width: 20px;
  height: 20px;
  background: url('../images/delete.svg') no-repeat center;
  background-size: contain;
  flex-shrink: 0;
}
.delete-card-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.cancel-campus {
  max-width: 400px;
}
.cancel-campus-img {
  width: 108px;
}
.cancel-campus-title {
  font-size: 21px;
  font-weight: 800;
  line-height: normal;
  margin: 20px 0 10px;
  max-width: 175px;
}
.cancel-campus-text {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.80);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.cancel-campus-text.warning {
  color: #DC4038
}
.cancel-campus-button-wrapper {
  margin-top: 34px;

  &-success {
    margin-top: 20px;
    max-width: 220px;
  }
}
.checkbox-label {
  color:  #47494A;
  font-size: 14px;
  line-height: normal;
  display: flex;
  cursor: pointer;

  input {
    display: none;
  }

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background: url('../images/checkbox.svg') no-repeat center;
    background-size: contain;
    flex-shrink: 0;
    margin-right: 12px;
  }

  &.checked {
    &::before {
      background-image: url('../images/checkbox-checked.svg');
    }
  }
}
.button {
  color: #FFF;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  background: #DC4038; 
  border-radius: 17.5px; 
  padding: 12px 0 9px;
  border: none;
  outline: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s;

  &-full {
    width: 100%;
  }

  &-secondary {
    color: #000;
    background: #FFD600; 
  }

  &:disabled {
    cursor: initial;
    opacity: 0.5;
  }
}
@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(4px) rotate(0deg);
  }
  100% {
    transform: translateY(4px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(4px) rotate(0deg);
  }
  100% {
    transform: translateY(4px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-3px) rotate(0deg);
  }
  100% {
    transform: translateY(-3px) rotate(45deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-3px) rotate(0deg);
  }
  100% {
    transform: translateY(-3px) rotate(45deg);
  }
}

@keyframes spinner {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes astronaut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes fog {
  0% {
    background-position-x: 0;
  }
  50% {
    background-position-x: 2000px;
  }
  100% {
    background-position-x: 0px;
  }
}

@keyframes fade {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes shadow {
  0%,
  45%,
  95%,
  100% {
    opacity: 1;
  }
  50%,
  90% {
    opacity: 0;
  }
}

@keyframes lineTopStop {
  from {
    transform: translateX(calc(-50vw - 150px));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes lineBottomStop {
  from {
    transform: translateX(calc(50vw + 150px));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes lineTop {
  0%,
  45%,
  95%,
  100% {
    transform: translateX(0vw);
  }
  50%,
  90% {
    transform: translateX(calc(-50vw - 150px));
  }
}

@keyframes lineBottom {
  0%,
  45%,
  95%,
  100% {
    transform: translateX(0vw);
  }
  50%,
  90% {
    transform: translateX(calc(50vw + 150px));
  }
}

@keyframes caseTop {
  0%,
  52%,
  90%,
  100% {
    opacity: 0;
  }
  54%,
  85% {
    opacity: 1;
  }
}

@keyframes caseBottom {
  0%,
  50%,
  90%,
  100% {
    opacity: 0;
  }
  52%,
  85% {
    opacity: 1;
  }
}

@keyframes blockText {
  0%,
  52% {
    transform: translateX(calc(-100% - 10px));
  }
  53%,
  85%,
  100% {
    transform: translateX(0%);
  }
}

@media (max-width: 1920px) {
  .main .mainpage-group img {
    height: 100vh;
  }
}

@media (max-width: 1000px) {
  h1,
  h2,
  h3,
  h4 {
    font-size: 30px;
  }
  .header {
    p {
      font-size: 13px;
    }
    .menu-button {
      display: block;
    }
    .header-top .header-menu-block {
      display: none;
    }
    .header-menu .header-menu-block {
      display: block;
      padding: 20px 0;
      a {
        color: #ffffff;
        line-height: 2;
        font-size: 15px;
        display: block;
        font-family: 'LatoBold';
        text-transform: uppercase;
      }
    }
  }
  .main .mainpage-group .block-group {
    left: 40%;
  }
  .change-language.in-header {
    display: none;
  }
  .change-language.in-header-menu {
    display: flex;
    margin-left: -4px;
  }
}

@media (max-width: 750px) {
  .section-in {
    min-height: calc(100vh - 190px);
  }
  .header .header-city-alphabet {
    column-count: 1;
  }
  .profile-container {
    padding: 25px;
    .form-container {
      width: 100%;
    }
  }
  .offer > img,
  .offer > div {
    width: 100%;
  }
  .case-item {
    > div {
      width: 100%;
      &.case-image {
        max-width: 450px;
        margin: 0 auto;
      }
    }
    &.reversed,
    &:nth-child(even) {
      .case-image {
        order: initial;
      }
    }
  }
  .text-group p {
    font-size: 25px;
  }
  .university {
    &.main-page {
      .section-in {
        padding: 50px 10px;
      }
    }
  }
  .block-group {
    p {
      font-size: 15px;
    }
    > div {
      width: 100px;
      height: 95px;
    }
  }
  .form {
    .form-in {
      & > div.form-part {
        width: 100%;
      }
    }
  }
  .footer .menu a {
    margin-right: 20px;
  }
  .checkbox-label,
  .cancel-campus-text {
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  h1,
  h2,
  h3,
  h4 {
    font-size: 20px;
  }
  .block-text {
    padding: 0 30px;
  }

  .profile-container {
    padding: 15px;
  }

  .header-top {
    .logo svg {
      width: 80px;
      margin-right: 10px;
    }
  }
  .header-auth {
    margin-left: 10px;
  }
  .law-links {
    a {
      display: block;
    }
  }

  .notfound {
    height: calc(100vh - 56px);
    background-image: url('../images/notfound-image-mob.jpg');
    background-size: cover;
    background-position: center;
    & .number,
    & .astronaut,
    & .fog {
      display: none;
    }
    &-in {
      padding-top: 70px;
      padding-bottom: 0;
      & .text {
        width: 100%;
        & h4 {
          font-size: 35px;
          line-height: 1.14;
          letter-spacing: 0.4px;
        }
        & p {
          line-height: 1.1;
          letter-spacing: 0.3px;
          margin-top: 24px;
        }
      }
    }
  }
  .footer {
    & .lifecell_logo span {
      margin-top: 20px;
    }
    & .menu {
      padding: 25px 10px 25px;
      &-in {
        display: block;
        padding: 0;
        text-align: center;
        & > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        & a {
          width: 100%;
          padding: 5px 0;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .footer .menu-in > div {
    display: flex;
    justify-content: space-between;
  }

  .main,
  .block-items {
    .button-yellow {
      max-width: 280px;
    }
  }
}
