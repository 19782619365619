.textField {
  margin: $textFieldMargin;

  &Content {
    position: relative;

    &BottomLine {
      &:before,
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }

      &:before {
        color: transparent;
        content: 'need text here to prevent subpixel zoom issue';
        transition: border-bottom-color $textFieldBottomLineTransitionDuration
          cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: $textFieldBottomLineBorder;
      }

      &:after {
        content: '';
        transform: scaleX(0);
        transition: transform $textFieldBottomLineTransitionDuration cubic-bezier(0, 0, 0.2, 1) 0ms;
        border-bottom: $textFieldBottomLineIsFocusedBorder;
      }

      &Focused {
        &:after {
          transform: scaleX(1);
        }
      }

      &Disabled {
        &:before {
          border-bottom-color: $textFieldBottomLineDisabledBorderColor;
        }
      }

      &Error {
        &:after {
          transform: scaleX(1);
          border-bottom-color: $textFieldBottomLineErrorBorderColor;
        }
      }
    }
  }

  &TextArea {
    resize: vertical;
    overflow: auto;
  }

  &Input {
    &-default {
      width: $textFieldWidth;
      height: $textFieldHeight;
      font-size: $textFieldFontSize;
      line-height: $textFieldLineHeight;
      padding: $textFieldPadding;
      color: $textFieldColor;
      background: $textFieldBackground;
      border: $textFieldBorder;
      border-radius: $textFieldBorderRadius;
      box-shadow: $textFieldBoxShadow;
      outline: none;

      &Focused {
        border: $textFieldIsFocusedBorder;
        box-shadow: $textFieldIsFocusedShadow;
        color: $textFieldIsFocusedColor;
      }

      &Disabled {
        background: $textFieldDisabledBackground;
      }

      &Error {
        border: $textFieldErrorBorder;
        color: $textFieldErrorColor;
      }
    }

    &-outlined {
      width: $textFieldOutlinedWidth;
      height: $textFieldOutlinedHeight;
      font-size: $textFieldOutlinedFontSize;
      line-height: $textFieldOutlinedLineHeight;
      padding: $textFieldOutlinedPadding;
      color: $textFieldOutlinedColor;
      background: $textFieldOutlinedBackground;
      border: $textFieldOutlinedBorder;
      border-radius: $textFieldOutlinedBorderRadius;
      outline: none;

      &Focused {
        border-color: $textFieldOutlinedIsFocusedBorderColor;
        color: $textFieldOutlinedIsFocusedColor;
        & + .textFieldLabel-outlined {
          color: $textFieldOutlinedIsFocusedLabelColor;
        }
      }

      &HasContent {
        & + .textFieldLabel-outlined {
          transform: $textFieldOutlinedHasContentLabelTransform;
        }
      }

      &Disabled {
        border-color: $textFieldOutlinedIsDisabledBorderColor;
        color: $textFieldOutlinedIsDisabledColor;
        & + .textFieldLabel-outlined {
          color: $textFieldOutlinedIsDisabledLabelColor;
        }
      }

      &ReadOnly {
        border-color: $textFieldOutlinedIsReadOnlyBorderColor;
        color: $textFieldOutlinedIsReadOnlyColor;
        & + .textFieldLabel-outlined {
          color: $textFieldOutlinedIsReadOnlyLabelColor;
        }
      }

      &Error {
        border-color: $textFieldOutlinedErrorBorderColor;
        & + .textFieldLabel-outlined {
          color: $textFieldOutlinedErrorColor;
        }
      }
    }

    &-bottomline {
      color: $textFieldBottomLineColor;
      background: $textFieldBottomLineBackground;
      padding: $textFieldBottomLinePadding;
      margin: $textFieldBottomLineMargin;
      font-size: $textFieldBottomLineFontSize;
      border: 0;
      vertical-align: middle;
      outline: none;
      width: 100%;

      &HasContent {
        & + .textFieldLabel-bottomline {
          transform: $textFieldBottomHasContentTransform;
        }
      }

      &Disabled {
        color: $textFieldBottomLineDisabledColor;
        & + .textFieldLabel-bottomline {
          color: $textFieldBottomLineDisabledLabelColor;
        }
      }

      &Error {
        & + .textFieldLabel-bottomline {
          color: $textFieldBottomLineErrorLabelColor;
        }
      }
    }
  }

  &Label {
    &-default {
      display: block;
      text-transform: capitalize;
      margin: 0;
      font-size: $textFieldLabelFontSize;
      font-weight: $textFieldLabelFontWeight;
      line-height: $textFieldLabelLineHeight;
      color: $textFieldLabelColor;
    }

    &-outlined {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      transform-origin: top left;
      transform: $textFieldOutlinedLabelTransform;
      transition: transform $textFieldOutlinedLabelTransitionDuration cubic-bezier(0, 0, 0.2, 1) 0ms;
      font-size: $textFieldOutlinedFontSize;
      padding: $textFieldOutlinedLabelPadding;
      color: $textFieldOutlinedLabelColor;
      background: $textFieldOutlinedLabelBackground;
    }

    &-bottomline {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      transform-origin: top left;
      font-size: $textFieldBottomLineFontSize;
      color: $textFieldBottomLineLabelColor;
      transform: $textFieldBottomLineLabelTransform;
      transition: transform $textFieldBottomLineLabelTransitionDuration cubic-bezier(0, 0, 0.2, 1)
        0ms;
    }
  }

  &HelpText {
    &-default {
      display: block;
      font-size: $textFieldHelpTextFontSize;
      line-height: $textFieldHelpTextLineHeight;
      color: $textFieldHelpTextColor;
      margin: $textFieldHelpTextMargin;
    }

    &-outlined {
      display: block;
      line-height: $textFieldOutlinedHelpTextLineHeight;
      color: $textFieldOutlinedHelpTextColor;
      margin: $textFieldOutlinedHelpTextMargin;
    }

    &-bottomline {
      display: block;
      line-height: $textFieldBottomLineHelpTextLineHeight;
      color: $textFieldBottomLineHelpTextColor;
      margin: $textFieldBottomLineHelpTextMargin;
    }
  }

  &ErrorMessage {
    &-default {
      composes: textFieldHelpText-default;

      color: $textFieldErrorMessage;
    }

    &-outlined {
      composes: textFieldHelpText-outlined;

      color: $textFieldOutlinedErrorMessage;
    }

    &-bottomline {
      composes: textFieldHelpText-bottomline;

      color: $textFieldBottomLineErrorMessage;
    }
  }
}
