:global {
  .select {
    &-outlined {
      &__control {
        font-size: $selectFieldOutlinedFontSize;
        border: $selectFieldOutlinedBorder !important;
        border-radius: $selectFieldOutlinedBorderRadius !important;
        min-height: $selectFieldOutlinedHeight !important;

        &:hover {
          cursor: pointer;
        }

        &--is-focused {
          box-shadow: none !important;
          border-color: $selectFieldOutlinedIsFocusedBorderColor !important;
        }

        &--is-disabled {
          background: #fff !important;
          border-color: #ccc !important;

          .select-outlined__placeholder {
            color: #ccc !important;
          }
        }
      }

      &__value-container {
        padding: $selectFieldOutlinedPadding !important;
      }

      &__placeholder {
        color: $selectFieldOutlinedColor !important;
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        z-index: 2 !important;
      }

      &__option {
        &--is-focused {
          background-color: $selectFieldOutlinedOptionIsFocused !important;
          cursor: pointer !important;
        }
        &--is-selected {
          background-color: $selectFieldOutlinedOptionIsSelected !important;
        }
      }
    }
  }
}

.select {
  margin: 0 0 20px 0;

  &-outlined {
    position: relative;

    label {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      pointer-events: none;
      margin: 0;
      background: #fff;
      padding: 0 8px;
      font-size: 20px;
      transform-origin: top left;
      transform: $textFieldOutlinedLabelTransform;
      transition: transform $textFieldOutlinedLabelTransitionDuration cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &Focused {
      label {
        color: yellow;
      }
    }

    &HasContent {
      label {
        transform: $textFieldOutlinedHasContentLabelTransform;
      }
    }

    &Disabled {
      label {
        color: #ccc;
      }
    }

    &Error {
      :global {
        .select-outlined__control {
          border: 2px solid $selectFieldOutlinedErrorColor !important;
        }
      }

      small {
        color: $selectFieldOutlinedErrorColor;
      }
    }
  }
}
